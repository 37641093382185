<template>
    <div>
        <!-- <div class="invoice-preview card z-depth-1" style="position:relative;min-height:21cm!important"> -->
        <div class="invoice-preview card" >
            <div class="card-header align-items-center d-flex justify-content-between py-2">
                <button @click="$router.go(-1)" class=" px-3 py-2  btn btn-grey btn-sm" > <i class=" fas fa-arrow-left" ></i> Retour</button>

                <mdb-dropdown dropleft> 
                <mdb-dropdown-toggle slot="toggle" color="primary" size="sm" class="px-3 py-2">
                    <span class="d-none d-md-inline-block">Actions</span>
                </mdb-dropdown-toggle>
                <mdb-dropdown-menu>
                    <mdb-dropdown-item v-if="!invoice.uid" @click="normaliser()"><i class="fas mr-1 fa-qrcode"></i> Normaliser</mdb-dropdown-item>
                    <mdb-dropdown-item v-if="invoice.uid" :disabled="true"><i class="fas mr-1 fa-qrcode"></i> Normalisée</mdb-dropdown-item>
                    <mdb-dropdown-item v-if="!invoice.uid" :disabled="!invoice.uid"> <i class="fas mr-1 fa-file-pdf"></i> Exporter en PDF</mdb-dropdown-item>
                    <mdb-dropdown-item v-if="invoice.uid" @click="exportPdf()"> <i class="fas mr-1 fa-file-pdf"></i> Exporter en PDF</mdb-dropdown-item>
                    <mdb-dropdown-item v-if="invoice.uid && (invoice.type === 'FV' || invoice.type === 'EV')" @click="makeAnnulation()"> <i class="fas mr-1 fa-undo-alt"></i>Annuler cette facture</mdb-dropdown-item>
                    <mdb-dropdown-item @click="$router.replace('/factures')"><i class="fas mr-1 fa-list-alt"></i> Liste des factures</mdb-dropdown-item>
                </mdb-dropdown-menu>
                </mdb-dropdown>
            </div>
            <!-- <div class="action-tec-preview z-depth-1"  >
                <div class="btn-tec-fil " v-if="!invoice.uid">
                    <button :class="(normalizeBtn) ? 'disabled' :''" @click="normaliser()" class="btn-tec-preview  btn-primary white-text" >
                        <i class=" fas fa-qrcode" ></i> Normaliser
                    </button>
                </div>
                <div class="btn-tec-fil " v-if="invoice.uid" >
                    <button :class="(pdfBtn) ? 'disabled' :''" @click="exportPdf()" class="btn-tec-preview btn-primary" >
                        <i class=" fas fa-file-pdf" ></i> Exporter PDF
                    </button>
                </div>
                <div class="btn-tec-fil " >
                    <button :class="(pdfBtn) ? 'disabled' :''" @click="$router.replace('/factures')" class="btn-tec-preview btn-primary" >
                        <i class=" fas fa-list-alt" ></i> Liste des factures
                    </button>
                </div>
            </div> -->
            <!-- L'entete de la facture preview -->
            <div class="header invoice-box">
                <div class="type-invoice">
                    <div class="type-box"  :style="(invoice.theme_color) ? 'background-color:'+invoice.theme_color : 'background-color:'+$store.state.auth.company.setting.theme_color" >
                        <div v-for="type in typeInvoices" :key="type.ref">
                            <div class="title"  v-if="type.ref === invoice.type">FACTURe</div>
                            <div class="detail text-center" v-if="type.ref === invoice.type" v-html="type.type.toLowerCase().replace('facture','')" ></div>
                        </div>
                    </div>

                    <div class="type-invoice-details">
                        <div class="type-facture_details">Facture N°: {{invoice.num_facture}}</div>
                        <div v-if="invoice.num_facture_initiale" class="type-facture_details">Facture Initiale N°: {{invoice.num_facture_initiale}}</div>
                        <div class="type-facture_details">Date: {{invoice.date_facture}}</div> 
                    </div>
                    
                </div>
                <div class="company">
                    <span class="ct1" >Information Basique</span>
                    <div class="name">
                        {{$store.state.auth.company.name}}
                    </div>
                    <div class="details">{{$store.state.auth.company.address}}</div>
                    <div class="details">{{$store.state.auth.company.ifu}}</div>
                    <div class="details">{{$store.state.auth.company.rccm}}</div>
                    <br>
                    <div class="details">{{$store.state.auth.company.phone}}</div>
                    <div class="details">{{$store.state.auth.company.email}}</div>
                </div>
            </div>
            <div style="clear:both"></div>
            <!-- Information du client -->
            <div class="info-customer-payment invoice-box pt-30">
                <div class="customer">
                        <span class="ct2" >Facture à:</span>
                        <div class="name" v-if="invoice.customers.designation.toLowerCase() !== 'divers'" >
                            {{invoice.customers.designation}}
                        </div>
                        <div class="name" v-else-if="invoice.customers.designation.toLowerCase() === 'divers'" >
                            <div class="name" v-if="invoice.client_specification">
                                {{invoice.client_specification}} 
                            </div>
                            <div class="name" v-else>
                                {{invoice.customers.designation}}
                            </div>
                        </div>
                        
                        <div class="details"> {{invoice.customers.address}}</div>
                        <div class="details"> {{invoice.customers.ifu}}</div>
                        <div class="details"> {{invoice.customers.rccm}}</div>
                        <br>
                        <div class="details"> {{invoice.customers.phone}}</div>
                        <div class="details"> {{invoice.customers.email}}</div>
                </div>

                <div class="otherinfo">
                    <div class="item"><span>Vendeur:</span>  {{invoice.users.id}} -  {{invoice.users.firstname}} {{invoice.users.lastname}}</div>
                    <div class="item"><span>Paiement:</span> {{invoice.payments.designation}} - {{totalTTC | roundNumber | formatNumber}}</div>
                    <div class="item" v-if="invoice.ref_bc" ><span>Reference Commande:</span>{{invoice.ref_bc}}</div>
                    <div class="item" v-if="invoice.ref_bl" ><span>Reference Livraison:</span>{{invoice.ref_bl}}</div>
                    <div class="item" v-if="invoice.ref_bh" ><span>Reference Autres:</span>{{invoice.ref_bh}}</div>
                </div>
            </div>
            <div style="clear:both"></div>
            <!-- Information des articles -->
            <div class=" pt-5 mt-3 invoice-table invoice-box pt-30 page-break-inside">
                <table>
                    <thead>
                        <tr :style="
                                (invoice.theme_color) ? 'background-color:'+invoice.theme_color : 'background-color:'+$store.state.auth.company.setting.theme_color">
                            <th>REF</th>
                            <th>Désignation</th>
                            <th>Qte</th>
                            <th>PUHT</th>
                            <th>Total HT</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="detail in invoice.invoice_details " :key="detail.id">
                            <td>{{detail.ref}}</td>
                            <td >{{detail.designation}}</td>
                            <td>
                                <span v-if="detail.article">{{detail.quantity | formatNumber}}</span>
                            </td>
                            <td>{{detail.puht | formatNumber}}</td>
                            <td>{{detail.mht | formatNumber}}</td>
                        </tr> 
                    </tbody>
                </table>
            </div>

            <!-- Information Sub Menu -->
            <div class="invoice-sub invoice-box pt-30 page-break-inside">
                <div class="mcf">
                    <div class="qr" v-if="invoice.qr_code_file">
                        <img width="100" :src="$store.state.url+'storage/qrcodes/'+invoice.qr_code_file" alt="">
                    </div>
                    <div class="details" v-if="invoice.uid" >
                        <div class="details-i">{{invoice.code_me_ce_fdgi}}</div>
                        <div class="details-i">NIM: {{invoice.nim}} </div>
                        <div class="details-i">Compteurs: {{invoice.counters}}</div>
                        <div class="details-i">Heure: {{invoice.confirm_date}}</div>
                    </div>
                </div>
                <div class="sub">
                    <table>
                        <tr class="ttc" :style="(invoice.theme_color) ? 'color:'+invoice.theme_color : 'color:'+$store.state.auth.company.setting.theme_color">
                            <td>Total TTC</td>
                            <td>{{totalTTC | roundNumber | formatNumber}}</td>
                        </tr>
                        <tr>
                            <td>
                                <div></div>
                                <div v-for="ti in totalHT" :key="ti.bout">Total HT [ {{ti.bout}} ] <span v-if="ti.tva > 0" > {{ti.tva}} % </span></div>
                                <div v-if="totalTS.total > 0">Total TS [ {{totalTS.bout}} ] <span v-if="totalTS.tva > 0" > {{totalTS.tva}} % </span></div>
                                <div  v-if="totalTVA.total > 0">Total TVA [ {{totalTVA.bout}} ]  <span v-if="totalTVA.tva > 0" > {{totalTVA.tva}} % </span> </div>
                                <div v-if="totalAIB > 0">Total AiB {{invoice.rate_aib}}%</div>

                            </td>
                            <td>
                                <div v-for="ti in totalHT" :key="ti.bout" >{{ti.total | roundNumber | formatNumber}}</div>
                                <div v-if="totalTS.total > 0">{{totalTS.total | roundNumber | formatNumber}}</div>
                                <div  v-if="totalTVA.total > 0">{{totalTVA.total | roundNumber | formatNumber}}</div>
                                <div v-if="totalAIB > 0" >{{totalAIB | roundNumber | formatNumber}}</div>

                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle } from 'mdbvue';
export default {
    components:{
        mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle
    },
    props:{
        btnnormalize:{
            type:Boolean,
            default: false
        },
        invoice:{
            type:Array,
            default: ()=>[]
        },
        typeInvoices:{
            type:Array,
            default: ()=>[]
        },
        totalTTC:{
            default: ()=>[]
        },
        totalTS:{
            default: ()=>[]
        },
        totalAIB:{
            default: ()=>[]
        },
        totalTVA:{
            default: ()=>[]
        },
        totalHT:{
            default: ()=>[]
        }
        
    },
    data(){
        return {
            pdfBtn:false,
            normalizeBtn:false,
        }
    },
    methods:{
        exportPdf() {
            this.$emit('pdf_input',this.invoice)
        },

        makeAnnulation(){
            this.$emit('cancel_invoice',this.invoice)
        },
        normaliser(){
            this.$emit('normaliser_input',this.invoice)
        }

    }
}
</script>

<style lang="scss" scoped>
.invoice-preview{
    background-color: white;
    font-family: 'Nunito','Roboto',sans-serif;
}

.invoice-box {
    padding: 50px 50px;
}

.pt-30{
    padding-top: 30px;
}

.ct1{
    text-decoration: underline;
    font-size: 12px;
    font-weight: 600;
}

.ct2{
    font-size: 15px;
    font-weight: 500;
}
/**
*Entete de la facture
*/
.header.invoice-box{
    display: flex;
    justify-content: space-between;
    min-height: 170px;
    height: 170px;
    .type-invoice{
        flex-grow: 1;
        .type-box{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: auto;
            min-height:50px;

            color: white;
            font-size: 15px;
            font-weight: 600;
            padding-bottom: 5px;

            div{
                .title{
                    font-weight: 700;
                    letter-spacing: 2px;
                    font-size: 35px;
                    text-transform: uppercase;
                }

                .detail{
                    margin-top: -10px;
                    font-weight: 600;
                    letter-spacing: 1px;
                    font-size: 14px;
                    text-transform: capitalize;
                }
            }
            
            
        }

        .type-invoice-details{
            padding-top: 8px;
            padding-left: 30px;
            .type-facture_details{
                text-align: left;
                font-weight: 600;
                font-size: 14px;
            }

        }
        
        
    }

    .company{
        flex-grow: 3.5;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        text-align: right;
        width: 300px;
        .name{
            font-size: 20px;
            padding-top: 20px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .details{
            font-size: 13px;
            font-weight: 300;
        }
    }
}

.info-customer-payment.invoice-box{
    display: flex;
    justify-content: space-between;
    height: 50px;
    .customer{
        padding-left: 30px;
        
        margin-top: -10px;
        .name{
            font-weight: 600;
            font-size: 20px;
        }

        .details{
            font-size: 13px;
            font-weight: 300;
        }
    }

    .otherinfo{
        margin-top: 50px;
        font-size: 13px;
        font-weight: 300;
        text-align: justify;
        .item span{
            text-transform: capitalize;
            font-weight: 600;
        }
    }
}

table{
    width: 100%;
    border-spacing:0px;
    border-collapse: collapse;
}

.invoice-table table{
    margin-top: 70px;
    thead tr{
        th{
        text-align: center !important;
            padding: 13px 21px;
            color: white;
            font-weight: 400;
        }
    }

    tbody tr{
        background-color: rgba(129, 125, 125, 0.075);

        &:nth-child(2n+2){
            background-color: white ;
        }
        td{
            padding:15.5px 21px;
            text-align: center !important;
        }
    }
}

.invoice-sub.invoice-box{
    margin-top:-50px;
    display: flex;
    justify-content: space-between;

    .mcf{
        flex-grow: 1.5;
        width: 300px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .details{
            font-weight: 600;
            padding-left: 10px;
            font-size: 14px;

            .details-i{
                padding: 1px 0px;
            }
        }
    }

    .sub{
        flex-grow: 1;
        width: 200px;

        table{
            tr{
                text-align: left;

                &.ttc{
                    

                    td{
                        text-align: center;
                        font-size: 16px;
                        padding: 15px 10px;
                        font-weight: 700;
                    }
                }

                td{
                    text-align: center;
                    background-color: rgba(128, 128, 128, 0.068);
                    padding: 10px 10px;
                    font-size: 14px;
                    font-weight: 500;

                    div{
                        padding: 1px 0px;
                    }

                }
            }
        }
    }
}

@media (max-width: 768px) { 
    .invoice-preview{
        display:none;
    }

    .invoice-preview-mobile{
        display:block;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) { 
    .header.invoice-box{
        .type-invoice{
            .type-box{
                .title{
                    font-size: 25px;
                }
                .detail{
                    font-size: 10px;
                }
            }
        }

        .company{
        .name{
            font-size: 18px;
            padding-top: 20px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .details{
            font-size: 13px;
            font-weight: 300;
        }
    }
    }

    .invoice-sub.invoice-box{
        margin-top:-50px;
        display: flex;
        justify-content: space-between;

        .mcf{
            flex-grow: 1.5;
            width: 300px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .details{
                font-weight: 600;
                padding-left: 0px;
                font-size: 14px;

                .details-i{
                    padding: 1px 0px;
                }
            }
        }

        .sub{
            flex-grow: 1;
            width: 200px;

            table{
                tr{
                    text-align: left;

                    &.ttc{
                        

                        td{
                            text-align: center;
                            font-size: 16px;
                            padding: 15px 10px;
                            font-weight: 700;
                        }
                    }

                    td{
                        text-align: center;
                        background-color: rgba(128, 128, 128, 0.068);
                        padding: 5px 10px;
                        font-size: 12px;
                        font-weight: 500;

                        div{
                            padding: 1px 0px;
                        }

                    }
                }
            }
        }
    }
}
</style>