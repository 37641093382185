<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-lg-9 col-lg-8 mx-auto">
                <preview-desktop
                    class="invoice-preview-desktop-component"
                    :pdfBtnN="normalizeBtn"
                    v-on:pdf_input="exportPdf"
                    v-on:cancel_invoice="cancelInvoice"
                    v-on:normaliser_input="normaliser"
                    :invoice='invoice'
                    :typeInvoices='typeInvoices'
                    :totalTTC="totalTTC"
                    :totalTS="totalTS"
                    :totalAIB="totalAIB"
                    :totalTVA="totalTVA"
                    :totalHT="totalHT"
                    :btnnormalize="normalizeBtn"
                >
                </preview-desktop> 
                <preview-mobile
                    class="invoice-preview-mobile-component"
                    v-on:pdf_input="exportPdf"
                    v-on:cancel_invoice="cancelInvoice"
                    v-on:normaliser_input="normaliser"
                    :invoice='invoice'
                    :typeInvoices='typeInvoices'
                    :totalTTC="totalTTC"
                    :totalTS="totalTS"
                    :totalAIB="totalAIB"
                    :totalTVA="totalTVA"
                    :totalHT="totalHT"
                    :btnnormalize="normalizeBtn"
                ></preview-mobile>
            </div>
        </div>
    </div>
</template>

<script> 
import download from "../../../services/download-fetch"         

import {
    // mdbTbl,mdbTblHead,mdbTblBody,
    // mdbDropdown,mdbDropdownItem,mdbDropdownMenu,mdbDropdownToggle
} from 'mdbvue'
import PreviewDesktop from './modules/previewDesktop'
import previewMobile from './modules/previewMobile'
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - DETAILS FACTURE',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        // mdbTbl,mdbTblHead,mdbTblBody,
        // mdbDropdown,mdbDropdownItem,mdbDropdownMenu,mdbDropdownToggle,
        // PdfExport
        PreviewDesktop,
        previewMobile
    },
    data(){
        return{
            pdfBtn:false,
            normalizeBtn:false,
            typeInvoices:[],
            mttc:0,
            mht:{},
            invoice:{}
        }
    },
    methods:{
        async normaliser(){
            let res = await this.$store.dispatch("swal/ultimate",{
                type:"warning",
                icon:"warning",
                title:"Voulez-vous vraiment normaliser?"
            });
            if (res.isDismissed) {
                return;
            }
            if(!this.normalizeBtn){
                this.mttc = 0
                this.mht = {}
                this.invoice = {}
                this.$nprogress.start()
                this.normalizeBtn = !this.normalizeBtn
                await this.$store.dispatch('invoice/normalize',this.$router.currentRoute.params.id)
                .then((response)=>{
                    this.$nprogress.done()
                    this.normalizeBtn = !this.normalizeBtn
                    this.loadInvoice()
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
                })
                .catch((error)=>{
                    this.$nprogress.done()
                    this.normalizeBtn = !this.normalizeBtn

                    // this.$router.replace('/factures')
                    this.$notify({
                           
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })
            }else{
                this.$notify({
                       
                    message: "Une normalisation est en cours, veuillez patienter.",
                    type: 'danger'
                })
            }
        },

        async exportPdf(row){
            let th = 0
            let doyouPrint = await this.$store.dispatch('swal/doYouPrintPdf')

            if(doyouPrint.isConfirmed){
                th = 0
            }else if (doyouPrint.isDenied){
                th = 1   
            }
            else{
                return 
            }

            this.$nprogress.start()
            await download({
                url:'/print/pdf/'+row.id+'?th='+th,
                isTh:th,
                filename:row.num_facture+'.pdf'
            }).then(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "PDF Généré avec succès",
                    type: 'success'
                })
            })
            .catch(() => {
                this.$nprogress.done();
                this.$notify({
                       
                    message: "Echec durant l'exportation, reessayer ulterieurement",
                    type: 'danger'
                })
            })
        },

        cancelInvoice (row){
            this.$router.push({name:"Creer Facture Avoir",params:{
                id:row.id
            }})
        },

        async loadInvoice(){
            await this.$store.dispatch('invoice/findById',this.$router.currentRoute.params.id)
            .then((response)=>{
                this.invoice = response.data.invoice
            })
            .catch((error)=>{
                this.$router.replace('/factures')
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },
        
        async loadTypeInvoice () {
            await this.$store.dispatch('type_invoice/findAll')
            .then(response => {
                this.typeInvoices = response.data.typeInvoices
                
            }).catch((error) => {
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        calculTotalTTC(){
            let total = 0;
            let totalAib = 0;
            this.invoice.invoice_details.forEach(article => {
                total = total + parseFloat(article.mttc)
                totalAib = totalAib + parseFloat(article.maib)
            }); 

            return total + totalAib
        },

        calculTotalTS(){
            let total = {
                total:0,
                tva:0,
                bout:""
            };
            this.invoice.invoice_details.forEach(article => {
                if (article.ts > 0) {
                   total = {
                        total:total.total + parseFloat(article.mts),
                        tva:article.tva_tax,
                        bout:article.bout_tax,
                    } 
                }
                
            }); 
            return total
        },

        calculTotalTVA(){
            let total = {
                total:0,
                tva:0,
                bout:""
            };
            this.invoice.invoice_details.forEach(article => {
                if (article.tva_tax > 0) {
                    total = {
                            total:total.total + parseFloat(article.mtva),
                            tva:article.tva_tax,
                            bout:article.bout_tax,
                        }
                }
            }); 
            return total
        },

        calculTotalAiB(){
            let total = 0;
            this.invoice.invoice_details.forEach(article => {
                total = total + parseFloat(article.maib)
            }); 
            return total
        },

        calculTotalHT(){
            this.invoice.invoice_details.forEach(article => {
                let $keys = Object.keys(this.mht);
                let is = false;
                for (let i = 0; i < $keys.length; i++) {
                    if ($keys[i] === article.bout_tax) {
                        is = true;
                    }
                }

                if(is){
                    this.mht[article.bout_tax] = {
                        total : parseFloat  (this.mht[article.bout_tax].total) + parseFloat  (article.mht),
                        bout : article.bout_tax,
                        tva:article.tva_tax
                    } 
                }
                else{
                    this.mht[article.bout_tax] = {
                        total :parseFloat( article.mht),
                        bout : article.bout_tax,
                        tva:article.tva_tax
                    }
                }
            });
            return this.mht
        }
    },
    created(){ 
        this.loadInvoice()
        this.loadTypeInvoice()
    },
    computed:{
        totalTTC(){
            return this.calculTotalTTC()
        },
        totalTS(){
            return this.calculTotalTS()
        },
        totalAIB(){
            return this.calculTotalAiB()
        },
        totalTVA(){
            return this.calculTotalTVA()
        },
        totalHT(){
            return this.calculTotalHT();   
        }
    }
}
</script>

<style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
    
    
    .invoice-preview-desktop-component{
        display:block;
    }

    .invoice-preview-mobile-component{
        display:none;
    }
    @media (max-width: 768px) { 
        .invoice-preview-desktop-component{
            display:none;
        }

        .invoice-preview-mobile-component{
            display:block;
        }
    }

    
</style>