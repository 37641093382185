<template>
    <div>
        <div class="invoice-preview-mobile  card z-depth-1">
            <div class="invoice-header">
                <div class="type-box"  :style="($store.state.auth.company.setting.theme_color) ? 'background-color:'+$store.state.auth.company.setting.theme_color : 'background-color:'+$store.state.auth.model.theme_color" >
                    <div v-for="type in typeInvoices" :key="type.ref">
                        <div class="title"  v-if="type.ref === invoice.type">FACTURe</div>
                        <div class="detail text-center" v-if="type.ref === invoice.type" v-html="type.type.toLowerCase().replace('facture','')" ></div>
                    </div>
                </div>
                <div class="pl10r30 type-invoice-details">
                    <div class="type-facture_details">Facture N°: {{invoice.num_facture}}</div>
                    <div v-if="invoice.num_facture_initiale" class="type-facture_details">Facture Initiale N°: {{invoice.num_facture_initiale}}</div>
                    <div class="type-facture_details">Date: {{invoice.date_facture}}</div> 
                </div>
                <hr>
                <div class=" pl10r30 company">
                    <span class="ct1" >Information Basique</span>
                    <div class="name">
                        {{$store.state.auth.company.name}}
                    </div>
                    <div class="details">{{$store.state.auth.company.address}}</div>
                    <div class="details">{{$store.state.auth.company.ifu}}</div>
                    <div class="details">{{$store.state.auth.company.rccm}}</div>
                    <br>
                    <div class="details">{{$store.state.auth.company.phone}}</div>
                    <div class="details">{{$store.state.auth.company.email}}</div>
                </div>
                <hr>
                <div class="  pl10r30 customer">
                    <span class="ct2" >Facture à:</span>
                    <div class="name" v-if="invoice.customers.designation.toLowerCase() !== 'divers'" >
                        {{invoice.customers.designation}}
                    </div>
                    <div class="name" v-else-if="invoice.customers.designation.toLowerCase() === 'divers'" >
                        <div class="name" v-if="invoice.client_specification">
                            {{invoice.client_specification}} 
                        </div>
                        <div class="name" v-else>
                            {{invoice.customers.designation}}
                        </div>
                    </div>
                    <div class="details"> {{invoice.customers.address}}</div>
                    <div class="details"> {{invoice.customers.ifu}}</div>
                    <div class="details"> {{invoice.customers.rccm}}</div>
                    <br>
                    <div class="details"> {{invoice.customers.phone}}</div>
                    <div class="details"> {{invoice.customers.email}}</div>
                </div>
                <hr>
                <div class="pl10r30 otherinfo">
                    <div class="item"><span>Vendeur:</span>  {{invoice.users.id}} -  {{invoice.users.firstname}} {{invoice.users.lastname}}</div>
                    <div class="item"><span>Paiement:</span> {{invoice.payments.designation}} - {{invoice.total}}</div>
                    <div class="item" v-if="invoice.ref_bc" ><span>Reference Commande:</span>{{invoice.ref_bc}}</div>
                    <div class="item" v-if="invoice.ref_bl" ><span>Reference Livraison:</span>{{invoice.ref_bl}}</div>
                    <div class="item" v-if="invoice.ref_bh" ><span>Reference Autres:</span>{{invoice.ref_bh}}</div>
                </div>
                <div class="" >
                    <mdb-tbl responsive>
                        <mdb-tbl-head   :style="($store.state.auth.company.setting.theme_color) ? 'background-color:'+$store.state.auth.company.setting.theme_color : 'background-color:'+$store.state.auth.model.theme_color" textWhite>
                            <tr class="">
                            <th>ITEM</th>
                            <th>QTE</th>
                            <th>PUHT</th>
                            <th>MTHT</th>
                            </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body class="">
                            <tr class="" scope="row" v-for="item in invoice.invoice_details" :key="item.id" >
                                <th style="font-size:0.78rem" scope="row">
                                {{item.designation}} [ {{item.bout_tax}} ]</th>
                                <td style="font-size:0.78rem">
                                    <span v-if="item.article">{{item.quantity | formatNumber}}</span>
                                </td>
                                
                                <td style="font-size:0.78rem">{{item.puht | roundNumber| formatNumber}}</td>
                                <td style="font-size:0.78rem">{{item.mht | roundNumber| formatNumber}}</td>
                            </tr>
                        </mdb-tbl-body>
                    </mdb-tbl>
                </div>
                <div>
                    <mdb-tbl responsive class="table-click">
                        <mdb-tbl-body class="click">
                            <tr  :style="($store.state.auth.company.setting.theme_color) ? 'color:'+$store.state.auth.company.setting.theme_color : 'color:'+$store.state.auth.model.theme_color">
                                <td style="font-weight:700">Total TTC</td>
                                <td style="font-weight:700">{{totalTTC | roundNumber | formatNumber}}</td>
                            </tr>
                            <tr>
                            <td>
                                <div></div>
                                <div v-for="ti in totalHT" :key="ti.bout">Total HT [ {{ti.bout}} ] <span v-if="ti.tva > 0" > {{ti.tva}} % </span></div>
                                <div v-if="totalTS.total > 0">Total TS [ {{totalTS.bout}} ] <span v-if="totalTS.tva > 0" > {{totalTS.tva}} % </span></div>
                                <div  v-if="totalTVA.total > 0">Total TVA [ {{totalTVA.bout}} ]  <span v-if="totalTVA.tva > 0" > {{totalTVA.tva}} % </span> </div>
                                <div v-if="totalAIB > 0">Total AiB {{invoice.rate_aib}}%</div>

                            </td>
                            <td>
                                <div v-for="ti in totalHT" :key="ti.bout" >{{ti.total | roundNumber | formatNumber}}</div>
                                <div v-if="totalTS.total > 0">{{totalTS.total | roundNumber | formatNumber}}</div>
                                <div  v-if="totalTVA.total > 0">{{totalTVA.total | roundNumber | formatNumber}}</div>
                                <div v-if="totalAIB > 0" >{{totalAIB | roundNumber | formatNumber}}</div>
                            </td>
                        </tr>
                        </mdb-tbl-body>
                    </mdb-tbl>
                </div>

                <div class="pl10r30 " style="min-height:150px">
                    <div class="mcf d-flex justify-content-between align-items-center flex-nowrap">
                        <div class="qr" v-if="invoice.qr_code_file">
                            <img width="100" :src="$store.state.url+'storage/qrcodes/'+invoice.qr_code_file" alt="">
                        </div>
                        <div class="details pl-3" v-if="invoice.uid" style="font-size:0.75rem; font-weight:600;">
                            <div class="details-i">{{invoice.code_me_ce_fdgi}}</div>
                            <div class="details-i">NIM: {{invoice.nim}} </div>
                            <div class="details-i">Compteurs: {{invoice.counters}}</div>
                            <div class="details-i">Heure: {{invoice.confirm_date}}</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 px-3">
                    <button class="btn btn-block btn-primary btn-md" v-if="!invoice.uid" @click="normaliser()" >Normaliser</button>
                    <button class="btn btn-block btn-primary btn-md" v-if="invoice.uid" @click="exportPdf()" >Exporter PDF</button>
                    <button class="btn btn-block btn-primary btn-md" style="font-weight: 500;" v-if="invoice.uid && (invoice.type === 'FV' || invoice.type === 'EV')" @click="makeAnnulation()" >Annuler cette facture</button>
                    <button class="btn btn-block btn-grey btn-md"  @click="$router.push({path:'/factures'})" >Liste des factures</button> 
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import {
    mdbTbl, mdbTblHead, mdbTblBody,
} from 'mdbvue'
export default {
    components:{
        mdbTbl, mdbTblHead, mdbTblBody,
    },
    props:{
        btnnormalize:{
            type:Boolean,
            default: false
        },
        invoice:{
            type:Array,
            default: ()=>[]
        },
        typeInvoices:{
            type:Array,
            default: ()=>[]
        },
        totalTTC:{
            default: ()=>[]
        },
        totalTS:{
            default: ()=>[]
        },
        totalAIB:{
            default: ()=>[]
        },
        totalTVA:{
            default: ()=>[]
        },
        totalHT:{
            default: ()=>[]
        }
        
    },
    data(){
        return {
            pdfBtn:false,
            normalizeBtn:false,

            modalArticleDetails: {
                show: false,
                edit: false,
                title: "Détails des articles",
                content: {},
            },
        }
    },
    methods:{
        exportPdf() {
            this.$emit('pdf_input',this.invoice)
        },

        makeAnnulation(){
            this.$emit('cancel_invoice',this.invoice)
        },

        normaliser(){
            this.$emit('normaliser_input')
        },

    }
}
</script>

<style lang="scss" scoped>
.pl10r30{
    padding: 10px 30px;
}
.invoice-preview-mobile{
    background-color: white;
    font-family: 'Nunito','Roboto',sans-serif;
}
.invoice-header{
    .type-box{
        color: white;
        font-size: 15px;
        font-weight: 700;
        padding: 10px;
        text-align: center;
        div{
            .title{
                font-weight: 700;
                letter-spacing: 2px;
                font-size: 35px;
                text-transform: uppercase;
            }

            .detail{
                margin-top: -10px;
                font-weight: 600;
                letter-spacing: 1px;
                font-size: 14px;
                text-transform: capitalize;
            }
        }
    }
    .type-invoice-details{
        .type-facture_details{
            text-align: left;
            font-weight: 600;
            font-size: 17px;
        }
    }
    .company{
        .name{
            font-size: 20px;
            padding-top: 20px;
            font-weight: 700;
            text-transform: uppercase;
        }
        .details{
            font-size: 15px;
            font-weight: 300;
        }
    }
    .customer{
        padding-left: 30px;
        .name{
            font-weight: 600;
            font-size: 20px;
        }

        .details{
            font-size: 15px;
            font-weight: 300;
        }
    }
    .otherinfo{
        font-size: 15px;
        font-weight: 300;
        text-align: justify;
        .item span{
            text-transform: capitalize;
            font-weight: 600;
        }
    }

    .ttc{
        font-weight: 600  !important;
    }
}
</style>